<template>
  <div class="team">
    <v-row class="mb-5" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.total_amount ? statistic.total_amount : 0) }}
              </h2>
              <span>المبلغ الكلي</span>
            </div>

            <v-icon size="30" color="primary" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.payment_amount ? statistic.payment_amount : 0) }}
              </h2>
              <span>المدفوعات</span>
            </div>

            <v-icon size="30" color="secondary" class="rounded-0"> fa-coins </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.discount_amount ? statistic.discount_amount : 0) }}
              </h2>
              <span>الخصم</span>
            </div>

            <v-icon size="30" color="warning" class="rounded-0"> fa-cash-register </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.remaining ? statistic.remaining : 0) }}
              </h2>
              <span>المتبقي</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center mb-3 subtitle-4 black--text">فواتير اخرى</h1>
      <h3 class="text-center mb-3 subtitle-5 black--text">{{ $route.params.name }}</h3>
      <v-row>
        <v-col md="3" sm="3" cols="12" align-self="center">
          <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="addDialog.open = true">
            اضافة فاتورة
            <v-icon right> fa-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" sm="3" cols="12">
          <v-text-field v-model="table.search" label="البحث" append-icon="mdi-magnify" single-line hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="table.loading"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :headers="headers"
            :items="driversData"
            :search="table.search"
            :items-per-page="10"
            item-key="class_school_id"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
            }"
          >
            <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
            <template v-slot:item.price="{ item }">
              {{ numberWithComma(item.price) }}
            </template>
            <template v-slot:item.total_amount="{ item }">
              {{ numberWithComma(item.total_amount) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberWithComma(item.payment_amount) }}
            </template>
            <template v-slot:item.discount_amount="{ item }">
              {{ numberWithComma(item.discount_amount) }}
            </template>
            <template v-slot:item.remaining="{ item }">
              {{ numberWithComma(item.remaining) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل</span>
                </v-tooltip> -->
              <v-tooltip bottom v-if="isAllowedToDelete(account_type)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF8A80" class="ml-2" v-bind="attrs" size="18" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FDD835" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="printPage(item)">
                    fa-print
                  </v-icon>
                </template>
                <span>طباعة</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row no-gutters>
                <!-- section_id -->
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="addData.section_id" :rules="rules.required" :loading="sections.loading"
                    :items="sections.data" item-text="name" item-value="_id" clearable outlined dense
                    label="اختيار قسم منتج" @click:clear="addData.section_id = null"
                    @change="getAllServices()"></v-autocomplete>
                </v-col>
                <!-- service_selected -->
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="addData.service_selected" :rules="rules.requiredObject"
                    :loading="services.loading" :items="services.data" item-text="name" clearable outlined dense
                    label="نوع الخدمة" return-object @click:clear="addData.service_type = null"
                    @change="service_selected_changed()"></v-autocomplete>
                </v-col>
                <!-- quantity -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.quantity" :error-messages="errors.rate" :rules="rules.required"
                    dense label="الكمية" outlined />
                </v-col>
                <!-- price -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.price" :error-messages="errors.rate" :rules="rules.required" dense
                    label="المبلغ" outlined />
                </v-col>
                <!-- discount_amount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.discount_amount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="الخصم" outlined />
                </v-col>
                <!-- salaryPlusDiscountAmount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.salaryPlusDiscountAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ الكلي بعد الخصم" outlined disabled
                    class="font-weight-bold black--text" />
                </v-col>
                <!-- payment_amount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.payment_amount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ المدفوع" outlined />
                </v-col>
                <!-- remainingAmount -->
                <v-col cols="12" md="6">
                  <v-currency-field v-model="addData.remainingAmount" :error-messages="errors.rate"
                    :rules="rules.required" dense label="المبلغ المتبقي" outlined disabled />
                </v-col>
                <!-- date -->
                <v-col cols="12" md="6">
                  <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.date" dense :rules="rules.date" label="تاريخ انشاء الفاتورة"
                        outlined readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- received_by -->
                <v-col cols="12" md="6" v-if="center_id !== $store.state.s_and_s_clink_id">
                  <v-combobox v-model="addData.received_by" :rules="rules.required" dense label="المستلم" outlined
                    clearable :items="employee.data" item-text="employee_name" item-value="_id"></v-combobox>
                </v-col>
                <!-- desc -->
                <v-col cols="12">
                  <v-textarea outlined rows="2" row-height="10" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="add">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->

    <!-- add dialog Payment-->
    <v-dialog v-model="addDialogPayment.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialogPayment.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="addPaymentsData.type"
                    :items="paymentsType"
                    dense
                    label="اختيار نوع العملية"
                    outlined
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-currency-field
                    v-model="addPaymentsData.amount"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="المبلغ"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-menu
                    v-model="menuStartDatePayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addPaymentsData.next_payment"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addPaymentsData.next_payment" @input="menuStartDatePayment = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    rows="3"
                    row-height="20"
                    v-model="addPaymentsData.desc"
                    label="الملاحظة"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialogPayment.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialogPayment.loading"
            :disabled="!addDialogPayment.isFormValidAdd"
            @click="addPayments"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.name" label="الاسم" :rules="rules.name" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="dialogEdit.loading"
            :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Class dialog -->

    <!--- dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import salaryOtherInvoiceApi from '@/api/salary_other_invoice'
import sectionApi from '@/api/section_other'
import servicesApi from '@/api/service_other'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'
import employeeApi from '@/api/employee'

export default {
  data() {
    return {
      rate: 0,

      errors: {},

      account_type: null,

      menuStartDate: false,

      menuStartDatePayment: false,

      menuEndDate: false,

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      services: {
        data: [],
        loading: false,
      },

      sections: {
        data: [],
        loading: false,
      },

      nextPaymentDisable: false,

      menuDate: false,

      editedItemInvoice: {},

      menuNextPayment: false,

      addPaymentsData: {
        name: null,
        amount: null,
        type: 'payment',
        next_payment: null,
        desc: null,
      },

      rulesPayment: {
        name: [value => !!value || 'الاسم مطلوب'],
        amount: [value => !!value || 'المبلغ مطلوب'],
        checkAmount: [value => value > 70 || 'noo'],
      },

      paymentsType: [
        { text: 'تسديد', value: 'payment' },
        { text: 'خصم', value: 'discount' },
      ],

      addDialogPayment: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      employee: {
        data: [],
        loading: false,
        selected: {},
        salary: null,
      },

      addData: {
        customer_id: null,
        price: null,
        payment_amount: null,
        discount_amount: 0,
        remainingAmount: null,
        next_payment: null,
        desc: null,
        service_type: null,
        service_selected: {},
        section_id: null,
        salaryPlusDiscountAmount: null,
        date: getCurrentDateInString(),
      },

      deleteItemLoading: false,

      dialogDelete: false,

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
      },

      editedItem: {},

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      table: {
        loading: false,
        search: null,
      },

      center_id: null,

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        { text: 'نوع الخدمة', sortable: false, value: 'service_name' },
        { text: 'تاريخ انشاء الفاتورة', sortable: false, value: 'date' },
        { text: 'مبلغ الخدمة', sortable: false, value: 'price' },
        // quantity
        { text: 'الكمية', sortable: false, value: 'quantity' },
        { text: 'المبلغ الكلي', sortable: false, value: 'total_amount' },
        { text: 'المبلغ المدفوع', sortable: false, value: 'payment_amount' },
        { text: 'الخصم', sortable: false, value: 'discount_amount' },
        { text: 'المتبقي', sortable: false, value: 'remaining' },
        { text: 'الموظف', sortable: false, value: 'received_by_name' },
        { text: 'الملاحظات', sortable: false, value: 'desc' },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      driversData: [],
      deleteItemLoading: false,
      dialogDelete: false,

      statistic: {
        currency: 'IQD',
        currencySymbol: ' IQD',
        discount: null,
        payment: null,
        salary: null,
        remaining: null,
      },
    }
  },

  watch: {
    'addData.price': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.payment_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.discount_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.remainingAmount': {
      handler() {
        if (this.addData.remainingAmount <= 0) {
          this.nextPaymentDisable = true
          this.addData.next_payment = null
        } else {
          this.nextPaymentDisable = false
        }
      },
      // deep: true,
    },
    'tableModifier.start_date': {
      handler() {
        this.getData()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getData()
      },
      // deep: true,
    },
  },

  mounted() {
    this.getData()
    this.getAllSections()
    const account_type = this.$store.state.results.account_type
    const results = JSON.parse(localStorage.getItem('results'))
    this.center_id = results.center_id
    if (this.center_id === this.$store.state.s_and_s_clink_id) {
      this.headers.splice(9, 1)
    }
    this.getEmployee()
    this.account_type = account_type
  },

  methods: {
    async getData() {
      this.table.loading = true

      const response = await salaryOtherInvoiceApi.get({
        isDeleted: false,
        search: null,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        isPaid: null,
        customer_id: this.$route.params.id,
      })

      this.statistic = response.data.results.otherData

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.driversData = response.data.results.data
      }
    },

    async getEmployee() {
      this.employee.loading = true

      const response = await employeeApi.getAll()

      if (response.status === 401) {
        this.employee.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.employee.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.employee.loading = false
        this.employee.data = response.data.results
      }
    },

    async getAllServices() {
      this.services.loading = true

      const response = await servicesApi.getAllBySection(this.addData.section_id)

      if (response.status === 401) {
        this.services.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.services.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.services.loading = false
        this.services.data = response.data.results
      }
    },

    async getAllSections() {
      this.sections.loading = true

      const response = await sectionApi.getAll()

      if (response.status === 401) {
        this.sections.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.sections.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.sections.loading = false
        this.sections.data = response.data.results
      }
    },

    async add() {
      this.addDialog.loading = true

      const response = await salaryOtherInvoiceApi.add({
        customer_id: this.$route.params.id,
        date: this.addData.date,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        mobile: this.addData.mobile,
        price: this.addData.price,
        payment_amount: this.addData.payment_amount,
        discount_amount: this.addData.discount_amount,
        received_by: this.addData.received_by,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    salaryPlusDiscountAmountChange() {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.price ? this.addData.price : 0) - (this.addData.discount_amount ? this.addData.discountAmount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
    },

    paymentChange() {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.payment_amount ? this.addData.payment_amount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    async addPayments() {
      this.addDialog.loading = true
      const response = await salary_other_payments_api.add({
        invoice_id: this.editedItemInvoice._id,
        name: this.addPaymentsData.name,
        amount: this.addPaymentsData.amount,
        type: this.addPaymentsData.type,
        next_payment: this.addPaymentsData.next_payment,
        desc: this.addPaymentsData.desc,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
        this.showDialogfunction(response.data.message, 'primary')
        this.addPaymentsData.name = null
        this.addPaymentsData.amount = null
        this.addPaymentsData.type = 'payment'
        this.addPaymentsData.next_payment = null
        this.addPaymentsData.desc = null
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true
      const response = await salaryOtherInvoiceApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getData()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    editItemPayment(item) {
      this.editedItemInvoice = { ...item }
      this.addDialogPayment.open = true
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.loading = true

      const response = await Api.editService(this.editedItem.name, this.editedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false

        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    getDateString(isoDate) {
      let date = new Date(isoDate)
      const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]

      return dateString
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    printPage(item) {
      let routeData = this.$router.resolve({ name: 'anotherBillDetailsInvoice' })

      window.open(routeData.href, '_blank')
      item.customer_name = this.$route.params.name
      item.customer_mobile = this.$route.params.phone
      localStorage.setItem('anotherBillDetailsInvoice', JSON.stringify(item))

      // router.push('studentBillInvoice')
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    service_selected_changed() {
      if (this.addData.service_selected) {
        this.addData.price = this.addData.service_selected.price
        this.addData.service_type = this.addData.service_selected._id
      } else {
        this.addData.price = null
        this.addData.service_type = null
      }
    },

    numberWithComma,

    isAllowedToDelete,
  },
}
</script>
